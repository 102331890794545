import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
  AccessibilityLabelPartial,
  TestIdPartial,
  SpacePartial,
} from '../../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/rn-search-input.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        disableCodeEditing
        code={snippet}
        platform="react-native"
        gitHubLink="forms/search-input"
        disableCodeEditing
      />

      <Section title="Props">
        <PropList header="Visual">
          <PropListItem name="isDisabled" types={['boolean']}>
            <Text>Determines whether the input is disabled.</Text>
          </PropListItem>

          <PropListItem name="size" types={['string']}>
            <Text>Determines the size of the input.</Text>
            <List type="unordered">
              <li>
                <code>small</code>
              </li>
              <li>
                <code>medium</code> (default)
              </li>
              <li>
                <code>large</code>
              </li>
            </List>
          </PropListItem>

          <SpacePartial />
        </PropList>

        <PropList header="Miscellaneous">
          <AccessibilityLabelPartial componentName="search input" />

          <TestIdPartial componentName="search input" />

          <PropListItem name="...props" types={[]}>
            <Text>
              You can pass in any of the other props from a{' '}
              <Link href="https://reactnative.dev/docs/textinput">
                text input
              </Link>{' '}
              component.
            </Text>
          </PropListItem>
        </PropList>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React Native"
        componentName="Forms / Search Input"
      />
    </PlatformTab>
  );
};

export default ReactNativeTab;
